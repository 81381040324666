import React, { useState } from 'react';
import { graphql } from 'gatsby';
import SingleArtist from '../components/Artists/single-artist';

// Previews
import { withPreview } from 'gatsby-source-prismic';

const Artist = ({ location, data }) => {
  const [activeFilter, setActiveFilter] = useState('Illustration');

  return (
    <SingleArtist
      data={data}
      location={location}
      activeFilter={activeFilter}
      setActiveFilter={setActiveFilter}
    />
  );
};

export default withPreview(Artist);

export const query = graphql`
  query SingleArtist($uid: String!) {
    prismicArtist(uid: { eq: $uid }) {
      uid
      data {
        name {
          html
          text
        }
        image {
          alt
          fluid {
            srcWebp
            srcSetWebp
          }
        }
        text {
          html
          text
        }
        short_text
        showreel {
          url
          html
          embed_url
          width
          height
        }
        animations {
          animation {
            uid
            document {
              ... on PrismicAnimation {
                id
                data {
                  video_title
                  video_client
                  video {
                    embed_url
                    thumbnail_url
                    width
                    height
                    provider_name
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicArtistBodyAnimation {
            slice_type
            id
            items {
              project_tags {
                document {
                  ... on PrismicProjectTags {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            primary {
              short_video {
                url
              }
              orientation
            }
          }
          ... on PrismicArtistBodyIllustration {
            slice_type
            id
            items {
              project_tags {
                document {
                  ... on PrismicProjectTags {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            primary {
              image {
                alt
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
        body1 {
          ... on PrismicArtistBody1Animation {
            slice_type
            id
            items {
              project_tags {
                document {
                  ... on PrismicProjectTags {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            primary {
              short_video {
                url
              }
              orientation
            }
          }
          ... on PrismicArtistBody1Illustration {
            slice_type
            id
            items {
              project_tags {
                document {
                  ... on PrismicProjectTags {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            primary {
              image {
                alt
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
        body2 {
          ... on PrismicArtistBody2Animation {
            slice_type
            id
            items {
              project_tags {
                document {
                  ... on PrismicProjectTags {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            primary {
              short_video {
                url
              }
              orientation
            }
          }
          ... on PrismicArtistBody2Illustration {
            slice_type
            id
            items {
              project_tags {
                document {
                  ... on PrismicProjectTags {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            primary {
              image {
                alt
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
        body3 {
          ... on PrismicArtistBody3Animation {
            slice_type
            id
            items {
              project_tags {
                document {
                  ... on PrismicProjectTags {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            primary {
              short_video {
                url
              }
              orientation
            }
          }
          ... on PrismicArtistBody3Illustration {
            slice_type
            id
            items {
              project_tags {
                document {
                  ... on PrismicProjectTags {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            primary {
              image {
                alt
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;
